export function getFirstLettersOfName(value, message) {
  let name = value.split(" ");
  let msg = message.split(":");
  let firstLetters = "";
  name.forEach((item) => {
    firstLetters += item[0].toUpperCase();
  });
  if (
    msg[0].includes("From") &&
    msg[0].includes(localStorage.getItem("userEmail"))
  ) {
  } else if (msg[0].includes("From")) {
    let newFirstLetter = msg[0].split(" ")[1][0].toUpperCase();
    firstLetters = newFirstLetter;
  }

  return firstLetters;
}

export function updateProtectedLinks(htmlString, assets = null) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const links = doc.querySelectorAll("a");
  const img = doc.querySelectorAll("img");
  const allElements = doc.body.querySelectorAll("*");
  // console.log('links', links, assets);
  if (links) {
    links.forEach((item) => {
      if (item.href.includes("protected_static")) {
        if (assets) {
          assets.forEach((asset) => {
            if (item.href.toLowerCase() === asset.url.toLowerCase()) {
              item.href = asset.public_url;
            }
          });
        }
      }
    });
  }

  if (img) {
    img.forEach((item) => {
      item.style.width = "100%";
      item.style.height = "auto";
    });
  }

  // Remove empty elements except images
  allElements.forEach((item) => {
    if (item.tagName.toLowerCase() !== "img" && !item.innerHTML.trim()) {
      item.remove();
    }
  });

  return doc.body.innerHTML; // Return updated HTML as string
}

export function extractUsernameFromMessage(value, assets = null) {
  let message = "";
  let updatedHtml = updateProtectedLinks(value, assets);
  let newValue = updatedHtml.split(":");
  if (newValue[0].includes("From")) {
    newValue.forEach((msg, i) => {
      if (i !== 0) {
        message += msg.replace("https", "https:");
      }
    });
  } else {
    message = updatedHtml;
  }

  return message;
}

export function showUserName(value, userEmailData) {
  let userName = "";
  if (value.includes(localStorage.getItem("userEmail"))) {
    userName = localStorage.getItem("userName");
  } else if (value.split(":")[0].includes("From")) {
    let email = userEmailData.filter(
      (item) => item.email === value.split(":")[0].split(" ")[1]
    );
    if (email.length) {
      userName = userEmailData.filter(
        (item) => item.email === value.split(":")[0].split(" ")[1]
      )[0].username;
    } else {
      userName = value.split(":")[0].split(" ")[1];
    }
  } else {
    userName = "Incorpify Team";
  }

  return userName;
}

export function appendEmoji(value, emoji) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, "text/html");

  // Find all <p> tags
  const pTags = doc.querySelectorAll("p");

  // Append emoji to the last <p> tag
  if (pTags.length > 0) {
    let lastPTag = pTags[pTags.length - 1];
    if (lastPTag.innerHTML.trim() === "<br>") lastPTag.innerHTML = "";
    lastPTag.append(emoji);
  }

  // Convert the modified document back to a string
  const modifiedHtmlString = doc.body.innerHTML;
  return value === "" ? emoji : modifiedHtmlString;
}

export function setUserDataCookie(data) {
  const jsonData = JSON.stringify(data);
  document.cookie = `userData=${encodeURIComponent(jsonData)}; path=/; max-age=1296000`; // Set for 15 days
}

function getCookie(name) {
  const cookieArray = document.cookie.split('; ');
  for (let cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) return decodeURIComponent(cookieValue);
  }
  return null;
}

export function getUserDataFromCookie() {
  const data = getCookie('userData');
  return data ? JSON.parse(data) : null;
}

export function isUserVerified(userId, browserName, system) {
  const userData = getUserDataFromCookie();
  if (!userData) return false;

  const user = userData.find(user => user.userId == userId);
  if (!user) return false;

  return user.user.some(session => session.browserName === browserName && session.system === system);
}



export function getBrowserInfo() {
  let userAgent = navigator.userAgent;
  let browserName, osType;

  // Determine browser name
  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    browserName = "Apple Safari";
  } else if (userAgent.indexOf("Edg") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown Browser";
  }

  // Determine OS type
  if (userAgent.indexOf("Win") > -1) {
    osType = "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
    osType = "MacOS";
  } else if (userAgent.indexOf("Linux") > -1) {
    osType = "Linux";
  } else if (/Android/i.test(userAgent)) {
    osType = "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    osType = "iOS";
  } else {
    osType = "Unknown OS";
  }

  return {
    browser: browserName,
    os: osType,
  };
}
