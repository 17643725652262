export function getDateAndTime(time) {
  let date = new Date(time);
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();

  let newDate = `${day} ${month.slice(0, 3)} ${year} at ${hour}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
  return newDate;
}

export function getDifferenceBetweenCurrentDate(inputDate) {
  const previousDate = new Date(inputDate);
  const currentDate = new Date();

  const diffTime = Math.abs(currentDate - previousDate); // Difference in milliseconds
  const diffHours = diffTime / (1000 * 60 * 60); // Convert to hours

  if (diffHours < 23) {
    return `${Math.floor(diffHours)}h`;
  } else {
    return `${Math.ceil(diffHours / 24) - 1}d`;
  }
}
