import React, { useState } from "react";
import { Clock, IncorpifyTeam, Reply, ThumpsUp } from "../../assets/Icons";
import TextEditor from "../TextEditor";
import { getDateAndTime } from "../../utils/formatTime";
import Replies from "../TrackRequestComponents/Replies";
import {
  extractUsernameFromMessage,
  getFirstLettersOfName,
  showUserName,
} from "../../utils/CommonHelper";
import { Flex } from "antd";

const UpdateAndReply = ({
  item,
  reply,
  handleChangeReplyValue,
  replyValue,
  handleFileChange,
  handleChangeEmoji,
  likeIds,
  unlikeComment,
  props,
  userEmailData,
}) => {
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [showReplies, setShowReplies] = useState(3);
  let userName = showUserName(item.body, userEmailData);
  const cancelReply = () => {
    setShowReplyEditor(false);
  };
  const handleChangeTextEditor = (content) => {
    handleChangeReplyValue(content);
  };

  const replies = async () => {
    await reply("reply", item.id, replyValue);
    setShowReplies(300);
  };

  return (
    <div className={`border pt-2 mt-5 rounded position-relative`}>
      <div
        className="d-flex px-4 align-items-center"
        style={{ gap: "5px", marginTop: "0.9rem" }}
      >
        <div className="d-flex align-items-center">
          <span
            className="rounded-circle fw-bold text-white d-flex align-items-center justify-content-center"
            style={{
              background: "#ff6221",
              width: "40px",
              height: "40px",
              paddingTop: userName !== "Incorpify Team" ? "2px" : "0px",
            }}
          >
            {userName === "Incorpify Team" ? (
              <IncorpifyTeam />
            ) : (
              getFirstLettersOfName(userName, item.body)
            )}
          </span>
        </div>
        <div className="d-flex w-100 inc-comment-header">
          <span
            style={{
              fontSize: "18px",
              color: "#454545",
              fontWeight: "500",
              paddingTop: "3px",
            }}
          >
            {showUserName(item.body, userEmailData)}
          </span>

          {/* <div> */}
          <span style={{ color: "#6F7490" }}>
            <Clock /> {getDateAndTime(item.created_at)}
          </span>
          {/* </div> */}
        </div>
      </div>
      <div className="my-4 px-4 message-container" style={{}}>
        <p
          style={{ color: "#6F7490" }}
          dangerouslySetInnerHTML={{
            __html: extractUsernameFromMessage(item.body, item.assets),
          }}
        />
      </div>
      <div className="border w-100" style={{ bottom: "0", left: "0" }}>
        <button
          className="w-50 bg-transparent p-2"
          style={{
            border: "none",
            borderRight: "1px solid #cbc2c2",
            color: "#6F7490",
          }}
          onClick={() =>
            likeIds ? unlikeComment(item.id) : reply("like", item.id, "")
          }
        >
          <span
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "5px" }}
          >
            {likeIds ? (
              <span>👍</span>
            ) : (
              <>
                <span>
                  <ThumpsUp />
                </span>
                <span>Like</span>
              </>
            )}
          </span>
        </button>
        <button
          className="w-50 bg-transparent p-2"
          style={{ border: "none", color: "#6F7490" }}
          onClick={() => setShowReplyEditor(true)}
        >
          <span
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "5px" }}
          >
            <span>
              <Reply />{" "}
            </span>
            <span>Reply</span>
          </span>
        </button>
      </div>
      <div>
        {item.replies.length > 0 &&
          item.replies.map((reply, i) => {
            if (i < showReplies)
              return (
                <div className="px-4 pt-3">
                  <Replies item={reply} userEmailData={userEmailData} />
                </div>
              );
          })}
        {showReplies < item.replies.length && (
          <Flex justify="center" className="my-6">
            <button
              className="btn-outline-Show-more"
              onClick={() => setShowReplies((prev) => prev + 3)}
            >
              Load more
            </button>
          </Flex>
        )}
      </div>
      {item.replies.length > 0 && !showReplyEditor && (
        <>
          <Flex align="center" className="p-4" gap={10}>
            <div
              className="rounded-circle fw-bold text-white d-flex align-items-center justify-content-center"
              style={{
                background: "#ff6221",
                width: "38px",
                height: "35px",
                fontSize: "15px",
              }}
            >
              <span
                style={{
                  paddingTop: userName === "Incorpify Team" ? "0px" : "2px",
                }}
              >
                {getFirstLettersOfName(localStorage.getItem("userName"), item.body)}
              </span>
            </div>
            <span className="d-block w-100">
              <input
                type="text"
                placeholder="Write a reply..."
                className="w-100 border border-info-subtle incorpify-update-input"
                style={{
                  borderRadius: "50px",
                  padding: "5px 10px",
                }}
                onFocus={(e) => setShowReplyEditor(true)}
              />
            </span>
          </Flex>
        </>
      )}
      {showReplyEditor && (
        <Flex gap={10} className="p-4">
          <div
            className="rounded-circle fw-bold text-white d-flex align-items-center justify-content-center"
            style={{
              background: "#ff6221",
              width: "38px",
              height: "35px",
              fontSize: "15px",
              lineHeight: 1,
              display: "inline-block",
            }}
          >
            <span
              style={{
                lineHeight: 1,
                display: "inline-block",
                paddingTop: userName === "Incorpify Team" ? "0px" : "2px",
              }}
            >
              {getFirstLettersOfName(localStorage.getItem("userName"), item.body)}
            </span>
          </div>
          <div className="w-100">
            <TextEditor
              cancelUpdate={cancelReply}
              handleChangeTextEditor={handleChangeTextEditor}
              updateValue={replyValue}
              update={replies}
              handleFileChange={handleFileChange}
              handleChangeEmoji={handleChangeEmoji}
              type="reply"
              isUpdated={false}
              props={props}
            />
          </div>
        </Flex>
      )}
    </div>
  );
};

export default UpdateAndReply;
