import React, { useEffect, useRef, useState } from "react";
import {
  AdobeAcrobat,
  AiIcon,
  ApkIcon,
  CssIcon,
  Csvicon,
  DmgIcon,
  Docicon,
  Docxicon,
  HtmlIcon,
  Jpegicon,
  Jpgicon,
  Mp3icon,
  Mp4icon,
  Pngicon,
  PptIcon,
  PptxIcon,
  PsdIcon,
  RarIcon,
  Svgicon,
  Txticon,
  UploadIcon,
  XlsIcon,
  Xlsxicon,
  ZipIcon,
} from "../../assets/Icons";
import TextEditor from "../TextEditor";
import { Button, Flex, Form, Popover, Skeleton, Upload } from "antd";
import { fetcher } from "../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import UpdateAndReply from "./UpdateAndReply";
import { appendEmoji, updateProtectedLinks } from "../../utils/CommonHelper";
import axios from "axios";

const RequestDetails = ({
  data,
  getSubItemData,
  setLoading,
  likeIds,
  getAllLikes,
  requireAction,
  file,
  userEmailData,
  timeLineId,
  status,
}) => {
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [updateValue, setUpdateValue] = useState("");
  const [showComments, setShowComments] = useState(5);
  const [replyValue, setReplyValue] = useState("");
  const [commentLoad, setCommentLoad] = useState(false);
  const [estimate, setEstimate] = useState("");
  const [timeLineConversion, setTimeLineConversion] = useState(false);
  const [tempUrlForComment, setTempUrlForComment] = useState(null);
  let assignee = "";
  let action = "";
  let timeline = "";
  const props = {
    onRemove: (file) => {
      //   const index = fileList.indexOf(file);
      //   const newFileList = fileList.slice();
      //   newFileList.splice(index, 1);
      //   setFileList(newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
  };
  if (data?.column_values) {
    data.column_values.forEach((item) => {
      if (item.id === "assigness__1") {
        assignee = item.text;
      } else if (item.id === requireAction) {
        action = item.text;
      } else if (item.id === timeLineId) {
        timeline = item.text;
      }
    });
  }

  const convertDate = (time) => {
    const [startDateStr, endDateStr] = time.split(" - ");
    // console.log(startDateStr, endDateStr);
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const options = { month: "short", day: "numeric", year: "2-digit" };
    let formattedDate = startDate.toLocaleDateString("en-US", options);
    if (endDateStr != "" && startDateStr !== endDateStr) {
      formattedDate += ` - ${endDate.toLocaleDateString("en-US", options)}`;
      setTimeLineConversion(true);
    }

    setEstimate(formattedDate);
    // return formattedDate;
  };

  const convertDateToDays = (time) => {
    const [startDateStr, endDateStr] = time.split(" - ");
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = endDate - startDate;

    // Convert milliseconds to days
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

    setEstimate(diffInDays + "d");

    // console.log(`Difference in days: ${diffInDays}`); // Output: 35
  };

  useEffect(() => {
    if (timeline) {
      convertDate(timeline);
    }
  }, []);

  // console.log("timeline", convertDate(timeline));

  const cancelUpdate = (value) => {
    setShowTextEditor(false);
  };

  const handleChangeTextEditor = (content) => {
    setUpdateValue(content);
  };

  const handleChangeReplyValue = (content) => {
    setReplyValue(content);
  };

  const handleChangeEmoji = (content, isUpdate) => {
    let value = appendEmoji(updateValue, content);
    if (isUpdate) {
      handleChangeTextEditor(value);
    } else {
      handleChangeReplyValue(value);
    }
  };

  const update = async () => {
    let updatedHtml = updateProtectedLinks(updateValue, data.assets);
    // let newTempValue = "";
    // if (tempUrlForComment != null) {
    //   data.assets.forEach((item) => {
    //     if (item.url === tempUrlForComment.add_file_to_column.url) {
    //       newTempValue = `<a href="${item.public_url}">${tempUrlForComment.add_file_to_column.name}</a>`;
    //     }
    //   });
    //   setTempUrlForComment(null);
    // }
    setLoading(true);
    let payload = {
      parent_id: "",
      item_id: data.id,
      // text_body: `From ${localStorage.getItem("userEmail")}: ${
      //   tempUrlForComment === null ? updateValue : newTempValue
      // }`,
      text_body: `From ${localStorage.getItem("userEmail")}: ${updatedHtml}`,
      email: localStorage.getItem("userEmail"),
    };
    try {
      const response = await fetcher(
        "/incorpify/update",
        "POST",
        JSON.stringify(payload)
      );
      if (response.success) {
        getSubItemData();
        setUpdateValue("");
      } else {
        toast.error("Something went wrong!");
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const reply = async (mode, id) => {
    setLoading(true);
    let updatedHtml = updateProtectedLinks(replyValue, data.assets);
    let payload = {
      mode: mode,
      update_id: id,
      item_id: data.id,
      text_body: `From ${localStorage.getItem("userEmail")}: ${updatedHtml}`,
      item_type: "update",
    };
    try {
      const reply = await fetcher(
        "/incorpify/updateReplyOrLike",
        "POST",
        JSON.stringify(payload)
      );
      if (reply.success) {
        getSubItemData();
        setReplyValue("");
        if (mode === "like") {
          getAllLikes();
        }
      } else {
        toast.error("Something went wrong!");
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  async function unlikeComment(commentId) {
    setLoading(true);
    try {
      const res = await fetcher(
        `/incorpify/dislikeUpdateOrReply/${commentId}`,
        "DELETE"
      );
      if (res.success) {
        await getAllLikes();
        setLoading(false);
      } else {
        setTimeout(() => {
          setLoading(false);
          toast.error("Invalid Id Found");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  const handleFileChange = async (e, name, mode) => {
    let files = e.file;
    let formData = new FormData();
    formData.append("item_id", data.id);
    formData.append("file_name", files.name);
    formData.append("file", files);
    formData.append("column_id", file);
    let token = localStorage.getItem("userToken");
    const upload = async () => {
      try {
        setLoading(true);
        const res = await axios.post(
          "https://onboardifyapi.tasc360.com/incorpify/uploadMondayFiles",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTimeout(() => {
          if (res.data.success) {
            toast.success(files.name + " Updated.");
            if (name) {
              setTempUrlForComment(res.data.data.data);
              let value = `<a href="${res.data.data.data.add_file_to_column.url}">${res.data.data.data.add_file_to_column.name}</a>`;
              if (mode === "update") {
                setUpdateValue(updateValue + value);
              } else {
                setReplyValue(replyValue + value);
              }
            }
          } else {
            toast.error("Something went wrong!");
            setLoading(false);
          }
        }, 2000);
      } catch (error) {
        toast.error("Something went wrong!");
        setLoading(false);
      }
    };

    await upload();
    getSubItemData();
  };

  const cleanURL = (url) => {
    // Remove special characters and decode URI encoding for clarity
    const decodedURL = decodeURIComponent(url);
    const cleanedURL = decodedURL.replace(/[^a-zA-Z0-9:/._-]/g, "");
    return cleanedURL;
  };

  const getUrlArray = (tempData) => {
    let urlData = [];
    tempData.forEach((item) => {
      if (item.id === file) {
        urlData = item.text
          .split(",")
          .map((url) => decodeURI(cleanURL(url)).trim());
      }
    });
    return urlData;
  };

  const getSvgIcon = (name) => {
    let tempArray = name.split(".");
    let svgType = tempArray[tempArray.length - 1];

    let icons = {
      csv: <Csvicon />,
      doc: <Docicon />,
      docx: <Docxicon />,
      png: <Pngicon />,
      jpg: <Jpgicon />,
      jpeg: <Jpegicon />,
      mp4: <Mp4icon />,
      svg: <Svgicon />,
      xlsx: <Xlsxicon />,
      pdf: <AdobeAcrobat />,
      mp3: <Mp3icon />,
      txt: <Txticon />,
      ppt: <PptIcon />,
      psd: <PsdIcon />,
      dmg: <DmgIcon />,
      ai: <AiIcon />,
      html: <HtmlIcon />,
      css: <CssIcon />,
      xls: <XlsIcon />,
      zip: <ZipIcon />,
      apk: <ApkIcon />,
      pptx: <PptxIcon />,
      rar: <RarIcon />,
    };

    return icons[svgType] !== undefined ? icons[svgType] : <AdobeAcrobat />;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        if (data.updates.length > showComments) {
          setCommentLoad(true);
          setTimeout(() => {
            setCommentLoad(false);
            setShowComments((prev) => prev + 5);
          }, 1000);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [commentLoad]);

  return (
    <>
      <div
        className="text-start inc-detail-container"
        style={{ background: "#6f74900f", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="d-flex flex-column border-bottom"
            style={{ paddingBottom: "15px", gap: "10px" }}
          >
            <span
              className="fw-bold"
              style={{ color: "#6F7490", fontSize: "17px" }}
            >
              Assignee
            </span>
            <span style={{ fontSize: "17px", color: "#6F7490" }}>
              {assignee}
            </span>
          </div>
          {timeline !== "" && (
            <div
              style={{
                fontSize: "17px",
                color: "#fff",
                textAlign: "center",
                minWidth: "150px",
                padding: "3px 10px",
                paddingTop: "5px",
                borderRadius: "50px",
              }}
              className={`${status === "Completed"
                ? "bg-success"
                : status.toLowerCase() === "stuck" ||
                  status.toLowerCase() === "pending" ||
                  status.toLowerCase() === "on hold"
                ? "bg-danger"
                : status.toLowerCase() === "locked" || status.toLowerCase() === "awaiting action"
                ? "bg-timeline_default"
                : "bg-warning"}`}
              onMouseOver={() => {
                if (timeLineConversion) {
                  convertDateToDays(timeline);
                }
              }}
              onMouseLeave={() => convertDate(timeline)}
            >
              {estimate}
            </div>
          )}
        </div>
        <div
          className="d-flex flex-column border-bottom"
          style={{ paddingTop: "15px", paddingBottom: "15px", gap: "10px" }}
        >
          <span
            className="fw-bold"
            style={{ color: "#6F7490", fontSize: "17px" }}
          >
            Required Action
          </span>
          <span style={{ fontSize: "17px", color: "#6F7490" }}>{action}</span>
        </div>
        <div
          className="d-flex flex-column border-bottom py-3"
          style={{ gap: "20px" }}
        >
          <span
            className="fw-bold"
            style={{ color: "#6F7490", fontSize: "17px" }}
          >
            Documents
          </span>
          {data.assets.length > 0 && (
            <Flex gap={10}>
              {data.assets.map((item, i) => {
                const urlList = getUrlArray(data.column_values);
                if (urlList.includes(decodeURI(cleanURL(item.url)))) {
                  return (
                    <>
                      <div>
                        <a
                          href={item.public_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getSvgIcon(item.name)}
                        </a>
                        <div className="mt-1">
                          <Popover content={item.name} trigger="hover">
                            <span>{item.name.slice(0, 6)}...</span>
                          </Popover>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </Flex>
          )}
          <span>
            <Upload
              {...props}
              showUploadList={false}
              onChange={(e) => handleFileChange(e, false, "update")}
              multiple={true}
            >
              <Button
                icon={<UploadIcon />}
                size="large"
                iconPosition="end"
                style={{
                  padding: "5px 10px",
                  background: "#83acf1",
                  color: "#ffffff",
                }}
              >
                Upload
              </Button>
            </Upload>
          </span>
        </div>
        <div
          className="d-flex flex-column"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <span
            className="fw-bold"
            style={{ color: "#6F7490", fontSize: "17px" }}
          >
            Latest Updates
          </span>
          <div className="mt-4 incorpify-comment-section">
            {showTextEditor ? (
              <div>
                <TextEditor
                  cancelUpdate={cancelUpdate}
                  handleChangeTextEditor={handleChangeTextEditor}
                  updateValue={updateValue}
                  update={update}
                  handleFileChange={handleFileChange}
                  type="update"
                  handleChangeEmoji={handleChangeEmoji}
                  isUpdated={true}
                  props={props}
                />
              </div>
            ) : (
              <span className="d-block">
                <input
                  type="text"
                  placeholder="Write an update..."
                  className="w-100 border border-info-subtle incorpify-update-input"
                  style={{ borderRadius: "5px", padding: "5px 10px" }}
                  onFocus={(e) => {
                    setShowTextEditor(true);
                  }}
                />
              </span>
            )}
            {data.updates.length > 0 &&
              data.updates.map((item, i) => {
                if (i < showComments)
                  return (
                    <UpdateAndReply
                      item={item}
                      key={i}
                      reply={reply}
                      handleChangeReplyValue={handleChangeReplyValue}
                      replyValue={replyValue}
                      handleFileChange={handleFileChange}
                      handleChangeEmoji={handleChangeEmoji}
                      isUpdated={true}
                      props={props}
                      likeIds={likeIds.includes(item.id)}
                      unlikeComment={unlikeComment}
                      userEmailData={userEmailData}
                    />
                  );
              })}
            <Flex justify="center" className="mt-4">
              {commentLoad && <Skeleton avatar paragraph={{ rows: 3 }} />}
            </Flex>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default RequestDetails;
